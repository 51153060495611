import { GeneralVacationModal, NonWorkingDaysTable } from 'components';
import { useModalState } from 'hooks';
import { TGeneralVacation } from 'models/GeneralVacation';
import { useCallback, useState } from 'react';
import { GeneralVacationsTable } from './components/GeneralVacationsTable';

import './NonWorkingDaysPage.styles.scss';

const NonWorkingDaysPage = () => {
  const [selectedGeneralVacation, setSelectedGeneralVacation] = useState<
    TGeneralVacation | undefined
  >();

  const {
    isOpen: isGeneralVacationModalOpen,
    openModal: openGeneralVacationModal,
    closeModal: closeGeneralVacationModal,
  } = useModalState();

  const closeGeneralVacation = useCallback(() => {
    closeGeneralVacationModal();
    setSelectedGeneralVacation(undefined);
  }, [closeGeneralVacationModal]);

  const [refreshKey, setRefreshKey] = useState(0);

  return (
    <div className='non-working-days'>
      {isGeneralVacationModalOpen && (
        <GeneralVacationModal
          onClose={closeGeneralVacation}
          isOpen={isGeneralVacationModalOpen}
          onSuccess={() => {
            setRefreshKey((prevKey) => prevKey + 1); // Increment to trigger refresh
          }}
          generalVacation={selectedGeneralVacation}
          isEditMode={false}
        />
      )}

      <GeneralVacationsTable
        vacationAddedRefreshKey={refreshKey}
        urlParamNames={{
          pageParamName: 'generalVacationsPage',
          yearParamName: 'generalVacationsYear',
          sortByStartDateParamName: 'generalVacationsSort',
        }}
        onAddGeneralVacationBtnClicked={openGeneralVacationModal}
      />

      <NonWorkingDaysTable
        urlParamNames={{
          pageParamName: 'nonWorkingDaysPage',
          searchParamName: 'nonWorkingDaysSearh',
          sortByEmployeeParamName: 'nonWorkingDaysSortByName',
          sortByStartDateParamName: 'nonWorkingDaysSortByStartDate',
        }}
      />
    </div>
  );
};

export default NonWorkingDaysPage;
