import { FC } from 'react';
import useCurrenciesStore from 'store/useCurrenciesStore';
import { formatNumber } from 'utils/formatNumber';

import './SalaryDisplay.styles.scss';

type SalaryDisplayProps = {
  defaultAmount?: number;
};

const SalaryDisplay: FC<SalaryDisplayProps> = ({ defaultAmount }) => {
  const { currencies } = useCurrenciesStore();
  const { defaultCurrency, secondaryCurrency, rate } = currencies || {};

  return defaultAmount ? (
    <div className='salary-display'>
      <span className='salary-display__primary'>
        {formatNumber(defaultAmount, 2)} {defaultCurrency}
      </span>
      {secondaryCurrency && rate && (
        <span className='salary-display__secondary'>
          {formatNumber(rate * defaultAmount, 2)} {secondaryCurrency}
        </span>
      )}
    </div>
  ) : (
    <span className='salary-display__secondary'>No info.</span>
  );
};

export default SalaryDisplay;
