import { Button, FieldWrapper, Modal } from 'components';
import SelectV2, { SelectV2Props } from 'components/SelectV2/SelectV2.component';
import { FC } from 'react';
import { FormProvider, UseFormReturn, useForm } from 'react-hook-form';
import Input, { InputProps } from 'components/Input/Input.component';
import { handleKeyDown } from 'pages/SettingsPage/components/MulitpliersSettings/components/MultiplierForm/MultiplierForm.component';
import { ModalProps } from 'components/Modal/Modal.component';

import './ExchangeRateModal.styles.scss';

export type ExchangeFormValue = {
  defaultCurrencyRate: string;
  secondaryCurrency: string;
  secondaryCurrencyRate: string;
};

type ExchangeRateFormProps = {
  defaultCurrency: string;
  secondaryCurrencyOptions: { value: string; label: string | number }[];
  onSubmit: (values: ExchangeFormValue) => void;
  isSubmitInProgress: boolean;
  onRemoveBtnClicked: () => void;
  methods: UseFormReturn<ExchangeFormValue>;
} & Pick<ModalProps, 'isOpen' | 'onRequestClose'>;

const ExchangeRateModal: FC<ExchangeRateFormProps> = ({
  defaultCurrency,
  secondaryCurrencyOptions,
  isSubmitInProgress,
  onSubmit,
  onRemoveBtnClicked,
  isOpen,
  onRequestClose,
  methods,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className='exchange-rate-modal'
      hasCloseIcon
    >
      <div className='exchange-rate-modal__title'>Exchange rate</div>
      <div className='exchange-rate-modal__description'>
        Change secondary currency and/or add exchange rate
      </div>
      <FormProvider {...methods}>
        <form
          className='exchange-rate-modal__form'
          onSubmit={methods.handleSubmit(onSubmit)}
          name='formDefaultCurrency'
        >
          <div className='exchange-rate-modal__form-fields'>
            <div className='exchange-rate-modal__default-currency'>
              <label>Default</label>
              <div className='exchange-rate-modal__field'>{defaultCurrency}</div>
              <FieldWrapper<InputProps>
                name='defaultCurrencyRate'
                fieldComponent={Input}
                onKeyDown={handleKeyDown}
                required
                className={'exchange-rate-modal__input-field'}
              />
            </div>
            <div className='exchange-rate-modal__secondary-currency'>
              <label>Secondary</label>
              <FieldWrapper<SelectV2Props>
                name='secondaryCurrency'
                fieldComponent={SelectV2}
                required
                options={secondaryCurrencyOptions}
                placeholder='Select currency'
                className={'exchange-rate-modal__input-field'}
              />
              <FieldWrapper<InputProps>
                name='secondaryCurrencyRate'
                fieldComponent={Input}
                onKeyDown={handleKeyDown}
                required
                placeholder='/'
                className={'exchange-rate-modal__input-field'}
              />
            </div>
          </div>
          <div className='exchange-rate-modal__btns-container'>
            <Button
              type='button'
              variant='outline'
              onClick={onRequestClose}
              className='exchange-rate-modal__cancel-btn'
            >
              Cancel
            </Button>
            <Button
              type='button'
              variant='outline'
              className='exchange-rate-modal__remove-currency-btn'
              onClick={onRemoveBtnClicked}
            >
              Remove secondary
            </Button>
            <Button
              disabled={!methods.formState.isDirty}
              loading={isSubmitInProgress}
              type='submit'
            >
              Save
            </Button>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default ExchangeRateModal;
