import { BackArrow, ChevronIcon, ThreeDotsIcon, VerifiedIcon } from 'assets';
import noImagePlaceholder from 'assets/No-Image-Placeholder.svg.png';
import { Button, DatePicker, Dropdown, FieldWrapper, Input, OverlaySpinner } from 'components';
import { UploadFile } from 'components/Dropzone';
import ImageUpload, { ImageUploadProps } from 'components/ImageUpload/ImageUpload.component';
import { InputProps } from 'components/Input/Input.component';
import { SectionBlock } from 'components/layout/SectionBlock';
import { APP_URI } from 'config';
import { API_PATH } from 'constant';
import { useAxios, useModalState } from 'hooks';
import { InvitationStatus, User } from 'models/User';
import { snackbar } from 'modules';
import EmployeeContext from 'pages/EmployeeProfilePage/context/EmploeeContext';
import { customResolver } from 'pages/ProfilePage/components/PersonalInformation/PersonalInformation.component';
import { uploadPicture } from 'pages/ProfilePage/utils/uploadProfilePicture';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import {
  LETTERS_REGEX,
  MSG_LETTERS_ONLY,
  MSG_REQUIRED,
  NAME_REGEX,
  PHONE_REGEX_381,
  PHONE_REGEX_NORMAL,
} from 'validation';
import { MSG_NAME_INVALID } from 'validation/messages';
import * as Yup from 'yup';
import InviteEmployee from '../InviteEmployee';
import { DeactivateEmployeeModal, RemoveEmployeeModal } from './components';

import './EmployeePersonalInformation.styles.scss';
import classNames from 'classnames';
import DateService from 'services/Date.service';
import { PERSONAL_ID_REGEX } from 'validation/regex';

type PersonalInformation = {
  firstName: string;
  lastName: string;
  email?: string;
  phoneNumber?: string;
  profilePicture: UploadFile[];
  address: string;
  birthDate: string;
  personalId: string;
};

const validation = Yup.object().shape({
  firstName: Yup.string()
    .required(MSG_REQUIRED)
    .matches(LETTERS_REGEX, MSG_LETTERS_ONLY)
    .matches(NAME_REGEX, MSG_NAME_INVALID)
    .max(30, 'First name too long'),
  lastName: Yup.string()
    .required(MSG_REQUIRED)
    .matches(LETTERS_REGEX, MSG_LETTERS_ONLY)
    .matches(NAME_REGEX, MSG_NAME_INVALID)
    .max(30, 'Last name too long'),
  phoneNumber: Yup.string().test(
    'phoneNumber',
    'Example: +38160123321 or 060123321, no blank spaces',
    (value) => {
      if (!value) return true;
      return PHONE_REGEX_381.test(value) || PHONE_REGEX_NORMAL.test(value);
    },
  ),
  personalId: Yup.string().test(
    'personalId',
    'Example: 0101995123456, no blank spaces, only numbers',
    (value) => {
      if (!value) return true;
      return PERSONAL_ID_REGEX.test(value);
    },
  ),
});

const EmployeePersonalInformation = () => {
  const { employee, updateEmployee } = useContext(EmployeeContext);

  const {
    firstName,
    lastName,
    email,
    phoneNumber,
    profileImageUrl,
    profileImageKey,
    inviteStatus,
    address,
    birthDate,
    personalId,
    id: employeeId,
    isActive,
  } = employee;
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isPersonalInformationOpen, setIsPersonalInformationOpet] = useState<boolean>(false);
  const initialValues = {
    firstName: firstName,
    lastName: lastName,
    email: email,
    phoneNumber: phoneNumber?.refferenceNumber.concat(phoneNumber.number) || '',
    profilePicture: [
      {
        blobURL: profileImageUrl || noImagePlaceholder,
        name: profileImageKey?.originalImage || 'missingImage',
        type: 'image/*',
      },
    ],
    address: address,
    birthDate: birthDate && DateService.formatDate(birthDate, 'yyyy-MM-dd'),
    personalId: personalId,
  };

  const methods = useForm<PersonalInformation>({
    mode: 'onChange',
    defaultValues: initialValues,
    resolver: customResolver(validation),
  });

  const navigate = useNavigate();

  const {
    isOpen: isDeactivateModalOpen,
    openModal: handleOpenDeactivateModal,
    closeModal: handleCloseDeactivateModal,
  } = useModalState();

  const {
    isOpen: isRemoveModalOpen,
    openModal: handleOpenRemoveModal,
    closeModal: handleCloseRemoveModal,
  } = useModalState();

  const openDropdown = useCallback(() => setIsOpen((old) => !old), [setIsOpen]);

  const handleEdit = useCallback(() => {
    setIsEditing(true);
    setIsOpen(false);
  }, []);

  const handleCancelEdit = useCallback(() => {
    methods.reset(initialValues);
    setIsEditing(false);
  }, [methods]);

  const handleDeactivate = useCallback(() => {
    handleOpenDeactivateModal();
    setIsOpen(false);
  }, []);

  const handleRemove = useCallback(() => {
    handleOpenRemoveModal();
    setIsOpen(false);
  }, []);

  const renderInvitationStatus = useCallback(
    (status: InvitationStatus) => {
      switch (status) {
        case 'PENDING':
          return (
            <div className='pending-invitation'>
              Invitation sent
              <InviteEmployee userId={employee?.id} variant='secondary' />
            </div>
          );
        case 'ACCEPTED':
          return (
            <>
              <Tooltip place='top' anchorSelect='.verified-icon' className='verified-icon__tooltip'>
                Invite accepted and access to account granted.
              </Tooltip>
              <VerifiedIcon className='verified-icon' />
            </>
          );
        default:
          return <InviteEmployee userId={employee?.id} />;
      }
    },
    [employee?.id],
  );

  const dropdownActions = useMemo(
    () => [
      { label: 'Edit', onClick: handleEdit },
      { label: !isActive ? 'Reactivate' : 'Deactivate', onClick: handleDeactivate },
      { label: 'Remove', onClick: handleRemove },
    ],
    [handleEdit, handleDeactivate, handleRemove, isActive],
  );

  const actionDropdown = useMemo(
    () => (
      <Dropdown
        renderInParent
        isOpen={isOpen}
        trigger={<ThreeDotsIcon />}
        toggleOpenDropdown={openDropdown}
        className='ne-personal-information__actions-dropdown'
      >
        <ul>
          {dropdownActions.map((action) => (
            <li key={action.label.toLowerCase()} onClick={action.onClick}>
              {action.label}
            </li>
          ))}
        </ul>
      </Dropdown>
    ),
    [isOpen, openDropdown],
  );

  const { request: updateEmployeeRequest, loading: isUpdating } = useAxios<User, any, any>({
    url: `${API_PATH.USERS}/${employee?.id}`,
    method: 'PATCH',
    onResponse: (response) => {
      snackbar.show({ message: 'Employee information updated successfully.', type: 'success' });
      setIsEditing(false);
      updateEmployee(response.data);
    },
    onError: (error) => snackbar.show({ message: error.message, type: 'error' }),
  });

  const { loading: loadingRemovePicture, request: removeEmployeePicture } = useAxios<
    User,
    any,
    any
  >({
    url: API_PATH.REMOVE_USER_IMAGE_BY_ADMIN(employeeId),
    method: 'POST',
    onResponse: (response) => {
      snackbar.show({ message: 'Profile picture successfully removed.', type: 'success' });
      setIsEditing(false);

      updateEmployee({ profileImageKey: undefined, profileImageUrl: undefined });
    },
    onError: (error) => {
      snackbar.show({ message: error.message, type: 'error' });
      methods.resetField('profilePicture');
    },
  });

  const { loading: loadingProfilePicture, request: uploadEmployeePicture } = useAxios<
    User,
    any,
    any
  >({
    url: API_PATH.UPLOAD_USER_IMAGE_BY_ADMIN(employeeId),
    method: 'POST',
    onResponse: (response) => {
      snackbar.show({ message: 'Profile picture uploaded successfully.', type: 'success' });
      setIsEditing(false);
      updateEmployee(response.data);
    },
    onError: (error) => {
      snackbar.show({ message: error.message, type: 'error' });
      methods.resetField('profilePicture');
    },
  });

  const { loading: loadingDeactivateEmployee, request: deactivateEmployee } = useAxios({
    url: `${API_PATH.DEACTIVATE_USER}/${employeeId}`,
    method: 'DELETE',
    onResponse: () => {
      snackbar.show({ message: 'User successfully deactivated.', type: 'success' });
      updateEmployee({ isActive: false });
      handleCloseDeactivateModal();
    },
    onError: (error) => {
      snackbar.show({ message: error.message, type: 'error' });
    },
  });

  const { loading: loadingReactivateEmployee, request: reactivateEmployee } = useAxios<
    User,
    any,
    any
  >({
    url: `${API_PATH.REACTIVATE_USER}/${employeeId}`,
    method: 'POST',
    onResponse: (response) => {
      snackbar.show({ message: 'User successfully reactivated.', type: 'success' });
      updateEmployee(response.data);
      handleCloseDeactivateModal();
    },
    onError: (error) => {
      snackbar.show({ message: error.message, type: 'error' });
    },
  });

  const { loading: loadingRemoveEmployee, request: removeEmployee } = useAxios({
    url: `${API_PATH.REMOVE_USER}/${employeeId}`,
    method: 'DELETE',
    onResponse: () => {
      snackbar.show({ message: 'User successfully removed.', type: 'success' });
      handleCloseDeactivateModal();
      navigate(`${APP_URI.employees}`);
    },
    onError: (error) => {
      snackbar.show({ message: error.message, type: 'error' });
    },
  });

  const onSubmit = useCallback(
    (data: PersonalInformation) => {
      const fields: Array<keyof PersonalInformation> = [
        'firstName',
        'lastName',
        'phoneNumber',
        'address',
        'birthDate',
        'personalId',
      ];
      const isAnyFieldDirty = fields.some((field) => methods.getFieldState(field).isDirty);
      // UPLOAD PROFILE IMAGE
      if (
        data?.profilePicture[0]?.blobURL &&
        data.profilePicture[0].blobURL !== noImagePlaceholder &&
        data.profilePicture[0].blobURL !== employee.profileImageKey?.originalImage &&
        methods.getFieldState('profilePicture').isDirty
      ) {
        const { blobURL, name, type } = data.profilePicture[0];
        uploadPicture({ blobURL, name, type }, uploadEmployeePicture);
      }

      // REMOVE PROFILE IMAGE
      if (
        employee?.profileImageKey?.originalImage &&
        data?.profilePicture[0]?.blobURL === noImagePlaceholder
      )
        removeEmployeePicture({});

      // UPDATE EMPLOYEE PERSONAL INFORMATION
      if (isAnyFieldDirty) {
        updateEmployeeRequest({
          payload: {
            firstName: data.firstName,
            lastName: data.lastName,
            address: data.address,
            birthDate: data.birthDate && DateService.formatDate(data.birthDate, 'yyyy-MM-dd'),
            personalId: data.personalId,
            phoneNumber: data.phoneNumber
              ? {
                  refferenceNumber: '+381',
                  number: data.phoneNumber?.startsWith('+381')
                    ? data.phoneNumber.substring(4, data.phoneNumber.length)
                    : data.phoneNumber.substring(1, data.phoneNumber.length),
                }
              : null,
          },
        });
      }
    },
    [
      methods,
      updateEmployee,
      removeEmployeePicture,
      uploadEmployeePicture,
      profileImageKey,
      employee,
    ],
  );
  const submitDisabled = useMemo(() => {
    return !methods.formState.isDirty && Object.keys(methods.formState.errors).length === 0;
  }, [methods.formState]);

  useEffect(() => {
    if (loadingProfilePicture || loadingRemovePicture || isUpdating) OverlaySpinner.show('.panel');
    OverlaySpinner.hide('.panel');
  }, [loadingProfilePicture, loadingRemovePicture, isUpdating]);

  useEffect(() => {
    methods.reset(initialValues);
  }, [employee]);

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        className={classNames(
          'ne-personal-information__form',
          !isActive && 'ne-personal-information--deactivated',
        )}
      >
        <Link to={APP_URI.employees} className='ne-personal-information__link'>
          <BackArrow />
          Go back
        </Link>
        {!employee.isActive && (
          <div className='ne-personal-information__deactivated-message'>
            This account has been deactivated. While you cannot perform certain actions, you can
            still manage documents, remove or reactivate the account.
          </div>
        )}
        <SectionBlock
          className='ne-personal-information__section'
          title={
            <p className={`ne-personal-information__accordion`}>
              <Button
                variant='icon'
                type='button'
                onClick={() => setIsPersonalInformationOpet(!isPersonalInformationOpen)}
                aria-label='Toggle vacations requests'
                className={`ne-personal-information__accordion--${isPersonalInformationOpen ? 'open' : 'close'}`}
              >
                <ChevronIcon />
              </Button>
              Personal information
            </p>
          }
          asideFromTitle={actionDropdown}
        >
          <DeactivateEmployeeModal
            isModalOpen={isDeactivateModalOpen}
            closeModal={handleCloseDeactivateModal}
            firstName={firstName}
            lastName={lastName}
            profileImageUrl={profileImageUrl || noImagePlaceholder}
            loadingDeactivateRequest={
              !employee.isActive ? loadingReactivateEmployee : loadingDeactivateEmployee
            }
            deactivateEmployee={() =>
              !employee.isActive ? reactivateEmployee({}) : deactivateEmployee({})
            }
            isEmployeeDeactivated={!employee.isActive}
          />
          <RemoveEmployeeModal
            isModalOpen={isRemoveModalOpen}
            closeModal={handleCloseRemoveModal}
            firstName={firstName}
            lastName={lastName}
            profileImageUrl={profileImageUrl || noImagePlaceholder}
            loadingRemoveRequest={loadingRemoveEmployee}
            removeEmployee={() => removeEmployee({})}
            email={email || ''}
          />
          <div>
            <div className='ne-personal-information'>
              <div className='ne-personal-information__fields-wrapper'>
                <div className='ne-personal-information__left'>
                  <div className='ne-personal-information__img-wrapper'>
                    <FieldWrapper<ImageUploadProps>
                      fieldComponent={ImageUpload}
                      name='profilePicture'
                      isProfilePicture
                      isDirty={methods.getFieldState('profilePicture').isDirty}
                      dropzoneOptions={{
                        maxFiles: 1,
                        multiple: false,
                        maxSize: 5 * 1024 * 1024,
                        accept: { 'image/*': ['.png', '.jpeg', '.jpg'] },
                      }}
                      isDisabled={!isEditing}
                      onError={() => {
                        methods.setError('root', {
                          type: 'validate',
                          message: 'Please provide adequate picture.',
                        });
                      }}
                      onAccept={() => {
                        methods.clearErrors('root');
                      }}
                      isEdit={isEditing}
                    />
                  </div>
                  <small className='ne-personal-information__tip'>
                    {isEditing && (
                      <div>
                        <span>Tip:</span> Choose an image where face of an employee is recognizable
                        - .JPG .PNG (Max 5MB)
                      </div>
                    )}
                  </small>
                </div>
                <div className='ne-personal-information__right'>
                  <FieldWrapper<InputProps>
                    fieldComponent={Input}
                    name='firstName'
                    label='First name'
                    disabled={!isEditing}
                  />
                  <FieldWrapper<InputProps>
                    fieldComponent={Input}
                    name='lastName'
                    label='Last name'
                    disabled={!isEditing}
                  />
                  <FieldWrapper<InputProps>
                    fieldComponent={Input}
                    name='email'
                    label='Email'
                    disabled
                  />
                  <FieldWrapper<InputProps>
                    fieldComponent={Input}
                    name='phoneNumber'
                    label='Phone number'
                    disabled={!isEditing}
                  />
                  {isPersonalInformationOpen && (
                    <>
                      <FieldWrapper<InputProps>
                        fieldComponent={Input}
                        name='address'
                        label='Address'
                        disabled={!isEditing}
                      />
                      <FieldWrapper<any>
                        fieldComponent={DatePicker}
                        name='birthDate'
                        label='Date of birth'
                        disabled={!isEditing}
                        minDate={new Date('January 1, 1900 00:00:00')}
                        showYear
                      />
                      <FieldWrapper<InputProps>
                        fieldComponent={Input}
                        name='personalId'
                        label='JMBG'
                        disabled={!isEditing}
                      />
                    </>
                  )}
                </div>
              </div>
              {!isEditing ? (
                isActive && (
                  <div className='ne-personal-information__invitation-status'>
                    {renderInvitationStatus(inviteStatus)}
                  </div>
                )
              ) : (
                <div className='ne-personal-information__editing-actions'>
                  <Button onClick={handleCancelEdit} variant='text'>
                    Cancel
                  </Button>
                  <Button
                    type='submit'
                    loading={isUpdating || loadingProfilePicture || loadingRemovePicture}
                    disabled={submitDisabled}
                  >
                    Save
                  </Button>
                </div>
              )}
            </div>
          </div>
        </SectionBlock>
      </form>
    </FormProvider>
  );
};

export default EmployeePersonalInformation;
