import { UploadFile } from 'components/Dropzone';
import { Modal } from 'components/Modal';
import { API_PATH } from 'constant';
import { useAxios } from 'hooks/useAxios';
import { VacationRequestData } from 'models/VacationRequest';
import { snackbar } from 'modules';
import { FC, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { getFileBlob } from 'utils/getFileBlob';
import VacationRequestForm from './components/VacationRequestForm/VacationRequestForm.component';

type VacationRequestProps = {
  request: VacationRequestData;
  isOpen: boolean;
  status: string;
  title: string;

  onClose: () => void;
  onSuccess: (request: VacationRequestData) => void;
  approveVacationRequest?: () => void;
  rejectVacationRequest?: () => void;
};

type VacationRequestValue = {
  file: UploadFile[];
};

const VacationRequestModal: FC<VacationRequestProps> = (props) => {
  const {
    request,
    isOpen,
    status,
    title,
    onClose,
    onSuccess,
    approveVacationRequest,
    rejectVacationRequest,
  } = props;

  const methods = useForm<VacationRequestValue>({
    mode: 'onTouched',
  });

  const { request: fulfillVacationRequest, loading: fulfillRequestLoading } = useAxios<
    any,
    any,
    any
  >({
    url: `${API_PATH.VACATIONS}/${request._id}/${request.status === 'Approved' ? 'pending-signing' : 'fulfill'}`,
    method: 'POST',
    onResponse(responseParam) {
      onSuccess(responseParam.data);
      onClose();

      snackbar.show({
        message: `You have successfully ${request.status === 'Approved' ? 'prepared vacation request for signing' : 'fulfilled  vacation request.'}`,
        type: 'success',
      });
    },
    onError: (error) => {
      error.message = error.response?.data.message || error.message;
      methods.setError('root', error);
      snackbar.show({
        message: error.response?.data.message || error.message,
        type: 'error',
      });
    },
  });

  const handleSubmitRequest = useCallback(
    async (value: VacationRequestValue) => {
      const formData = new FormData();
      formData.set('file', await getFileBlob(value.file[0]));
      fulfillVacationRequest({ payload: formData });
    },
    [fulfillVacationRequest],
  );

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} hasCloseIcon={true}>
      <VacationRequestForm
        isLoading={fulfillRequestLoading}
        onSubmit={handleSubmitRequest}
        methods={methods}
        request={request}
        status={status}
        approveVacationRequest={approveVacationRequest}
        rejectVacationRequest={rejectVacationRequest}
        title={title}
      />
    </Modal>
  );
};

export default VacationRequestModal;
