import { Button } from 'components/Button';
import Modal, { ModalProps } from 'components/Modal/Modal.component';
import { FC } from 'react';

import './RemoveSecondaryCurrencyModal.styles.scss';

type RemoveSecondaryCurrencyModalProps = {
  isRemoveSecondaryCurrencyLoading: boolean;
  onRemove: () => void;
} & Pick<ModalProps, 'isOpen' | 'onRequestClose'>;

const RemoveSecondaryCurrencyModal: FC<RemoveSecondaryCurrencyModalProps> = (props) => {
  const { isOpen, onRequestClose, isRemoveSecondaryCurrencyLoading, onRemove } = props;
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className='remove-secondary-currency-modal'
      hasCloseIcon
    >
      <div className='remove-secondary-currency-modal__container'>
        <h3>Are you sure you want to remove a currency?</h3>
        <p>
          By removing the secondary currency, salary information in that currency will not be
          available for employment contracts. You can always add the secondary currency again.
        </p>
        <div className='remove-secondary-currency-modal__btn-container'>
          <Button
            onClick={onRequestClose}
            type='button'
            variant='outline'
            className='remove-secondary-currency-modal__cancel-btn'
          >
            Cancel
          </Button>
          <Button
            className='remove-secondary-currency-modal__delete-btn'
            onClick={onRemove}
            loading={isRemoveSecondaryCurrencyLoading}
          >
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default RemoveSecondaryCurrencyModal;
