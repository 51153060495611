import { PenIcon, SalaryIcon } from 'assets';
import { Button } from 'components/Button';
import { Salary } from 'models/Salary';
import { FC, RefObject } from 'react';
import { SectionBlock } from 'components/layout/SectionBlock';
import { SalaryDisplay } from 'components';

import './SalaryVariablesCard.styles.scss';

type SalaryVariablesCardProps = {
  multiplierName: string;
  grossSalary?: Salary;
  netSalary?: Salary;
  onEditButtonClicked: () => void;
  containerRef: RefObject<HTMLDivElement>;
};

const SalaryVariablesCard: FC<SalaryVariablesCardProps> = (props) => {
  const { multiplierName, netSalary, grossSalary, onEditButtonClicked, containerRef } = props;

  return (
    <SectionBlock
      title={
        <>
          <div className='salary-icon'>
            <SalaryIcon />
          </div>
          Salary information
        </>
      }
      className='salary-variables-card'
      asideFromTitle={
        <Button
          variant='icon'
          onClick={onEditButtonClicked}
          className='salary-variables-card__button'
          aria-label='Edit'
        >
          <PenIcon />
        </Button>
      }
    >
      <div className='salary-variables-card__content' ref={containerRef}>
        <div className='salary-variables-card__row'>
          <b>Used multiplier:</b>
          <p>{multiplierName || '/'}</p>
        </div>
        <div className='salary-variables-card__row'>
          <b>Net salary:</b>
          <SalaryDisplay defaultAmount={netSalary?.amount} />
        </div>
        <div className='salary-variables-card__row'>
          <b>Gross salary:</b>
          <SalaryDisplay defaultAmount={grossSalary?.amount} />
        </div>
      </div>
    </SectionBlock>
  );
};

export default SalaryVariablesCard;
