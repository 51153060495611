import { createBrowserRouter, Navigate, Outlet, RouterProvider } from 'react-router-dom';
import React from 'react';
import {
  Employees,
  InvitationError,
  PasswordRecovery,
  PasswordReset,
  Profile,
  SetPassword,
} from 'components';
import { APP_URI } from 'config';
import { useAuth } from 'context';
import { ProtectedRoute } from './ProtectedRoutes';
import { LoginPage } from 'pages/LoginPage';
import { DashboardPage } from 'pages/DashboardPage';
import { TimeOffsPage } from 'pages/TimeOffsPage';
import { EmployeeProfilePage } from 'pages/EmployeeProfilePage';
import { Settings } from 'pages/SettingsPage';
import { DaysOffPage } from 'pages/DaysOffPage';
import { DocumentsPage } from 'pages/DocumentsPage';
import Error404Page from 'pages/Error404Page/Error404Page.page';
import { UserRole } from 'enums';
import { Loader } from 'assets';
import { RoleManagementPage } from 'pages/RoleManagementPage';

const Routes = () => {
  const { token, user } = useAuth();

  const adminRoles = [UserRole.ADMIN, UserRole.SUPER_ADMIN];
  const employeeRoles = [UserRole.EMPLOYEE];

  const renderElement = (Component: React.ComponentType, visibleFor: UserRole[]) => {
    return user ? (
      visibleFor.find((role) => role === user?.role) ? (
        <Component />
      ) : (
        <Error404Page />
      )
    ) : (
      <Loader className='main-spinner' />
    );
  };

  // Define routes accessible only to authenticated users
  const routesForAuthenticatedOnly = [
    {
      path: APP_URI.base,
      element: <ProtectedRoute />, // Wrap the component in ProtectedRoute
      children: [
        {
          path: APP_URI.base,
          element: (
            <Navigate
              to={
                user
                  ? user.role === 'ADMIN' || user.role === 'SUPER_ADMIN'
                    ? `${APP_URI.dashboard}`
                    : `${APP_URI.documents}`
                  : ''
              }
            />
          ),
        },
        {
          path: APP_URI.dashboard,
          element: renderElement(DashboardPage, adminRoles),
          children: [
            {
              path: '',
              element: renderElement(DashboardPage, adminRoles),
            },
            {
              path: ':id',
              element: renderElement(DashboardPage, adminRoles),
            },
          ],
        },
        {
          path: APP_URI.daysoff,
          element: renderElement(DaysOffPage, employeeRoles),
        },
        {
          path: APP_URI.documents,
          element: <DocumentsPage />,
        },

        {
          path: '/employees',
          element: <Outlet />,
          children: [
            {
              path: '',
              element: renderElement(Employees, adminRoles),
            },
            {
              path: APP_URI.manageEmployees,
              element: renderElement(EmployeeProfilePage, adminRoles),
            },
            {
              path: `${APP_URI.manageEmployees}/:id`,
              element: renderElement(EmployeeProfilePage, adminRoles),
            },
          ],
        },
        {
          path: APP_URI.profile,
          element: <Profile />,
        },
        {
          path: APP_URI.timeoffs,
          element: <Outlet />,
          children: [
            {
              path: '',
              element: renderElement(TimeOffsPage, adminRoles),
            },
            {
              path: ':id',
              element: renderElement(TimeOffsPage, adminRoles),
            },
          ],
        },
        {
          path: APP_URI.settings,
          element: renderElement(Settings, adminRoles),
        },
        {
          path: APP_URI.roles,
          element: renderElement(RoleManagementPage, [UserRole.SUPER_ADMIN]),
        },
        {
          path: APP_URI.login,
          element: (
            <Navigate
              to={
                user
                  ? user.role === 'ADMIN' || user.role === 'SUPER_ADMIN'
                    ? `${APP_URI.dashboard}`
                    : `${APP_URI.documents}`
                  : ''
              }
            />
          ),
        },
        { path: '*', element: <Error404Page /> },
      ],
    },
  ];

  // Define routes accessible only to non-authenticated users
  const routesForNotAuthenticatedOnly = [
    {
      path: APP_URI.login,
      element: <LoginPage />,
    },
    {
      path: APP_URI.passwordRecovery,
      element: <PasswordRecovery />,
    },
    {
      path: APP_URI.passwordReset,
      element: <PasswordReset />,
    },
    {
      path: APP_URI.setPassword,
      element: <SetPassword />,
    },
    {
      path: APP_URI.invitationError,
      element: <InvitationError />,
    },
  ];

  // Combine and conditionally include routes based on authentication status
  const router = createBrowserRouter([
    ...(!token ? routesForNotAuthenticatedOnly : []),
    ...routesForAuthenticatedOnly,
  ]);

  // Provide the router configuration using RouterProvider
  return <RouterProvider router={router} />;
};

export default Routes;
