type VacationRequestParams = {
  startDate: Date | string;
  endDate: Date | string;
  immediateSupervisor: string;
  substitute?: string;
  reason?: string;
  employeeName: string;
};

const formatDate = (date: Date | string): string => {
  return (date instanceof Date ? date.toLocaleDateString() : date).replace(/\//g, '.');
};

export const generateVacationRequestEmailText = ({
  startDate,
  endDate,
  immediateSupervisor,
  substitute,
  reason,
  employeeName,
}: VacationRequestParams): string => {
  const substituteText = substitute
    ? `, a u mom odsustvu ${substitute} će preuzeti moje odgovornosti`
    : '';

  return `Poštovani direktore,

Ovim putem Vas obaveštavam da bih želeo/la da iskoristim odmor u periodu od ${formatDate(
    startDate,
  )} do ${formatDate(endDate)}. Moj neposredni nadređeni, ${immediateSupervisor}, je već odobrio ovaj zahtev${substituteText}.
  
Razlog za traženje odmora je: ${reason || 'nije navedeno'}.
  
Molim Vas za potvrdu i odobrenje.
  
Srdačan pozdrav,
${employeeName}`;
};
