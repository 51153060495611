import React from 'react';
import { EmployeeCard } from 'components/EmployeeCard';
import { User } from 'models/User';

import './EmployeesLayout.styles.scss';

const EmployeesLayout = (props: {
  employees: User[];
  containerRef: React.MutableRefObject<HTMLDivElement | null>;
}) => {
  const { employees, containerRef } = props;

  return (
    <div className='nce-employees-layout__grid' ref={containerRef}>
      {employees.map((employee: User) => (
        <EmployeeCard
          key={employee.id}
          employeeId={employee.id}
          firstName={employee.firstName!}
          lastName={employee.lastName!}
          imageUrl={employee.profileImageUrl}
          isDeactivated={!employee.isActive}
        />
      ))}
    </div>
  );
};
export default EmployeesLayout;
