import { AddIcon, GridIcon, NoSearchResultsIcon, SearchIcon, TableIcon } from 'assets';
import { Button, EmployeesLayout, Input, LazyImage, OverlaySpinner } from 'components';
import { API_PATH } from 'constant';
import { useModalState } from 'hooks';
import { IAxiosParams } from 'hooks/useAxios';
import useDebounce from 'hooks/useDebounce';
import useInfinitePagination from 'hooks/useInfinitePagination';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import EmployeesTable from './components/EmployeesTable/EmployeesTable.component';
import NewEmployeeModal from './components/NewEmployeeModal/NewEmployeeModal.component';
import { mapUrlParamsToRequestParams } from './utils/utils';
import { User } from 'models/User';

import './Employees.styles.scss';

const axiosConfig: IAxiosParams<any, any> = {
  url: API_PATH.USERS,
  method: 'GET',
};

export type EmployeesUrlParamNames = {
  searchParamName: string;
  sortByParamName: string;
};

const urlParamsNames = { searchParamName: 'search', sortByParamName: 'sort' };

const Employees = () => {
  const INITIAL_WINDOW_SIZE = 200;

  const { isOpen: isPopupOpen, closeModal: closePopup, openModal: openPopup } = useModalState();
  const [zoomLevel, setZoomLevel] = useState(INITIAL_WINDOW_SIZE); // State to track zoom level
  const [displayGrid, setDisplayGrid] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchString, setSearchString] = useState('');
  const debounceString = useDebounce(searchString);
  const paramsFromUrl = mapUrlParamsToRequestParams(urlParamsNames, searchParams);

  const {
    listInnerRef,
    dataList,
    onScroll,
    setSearch,
    loading: loadingPagination,
    loadMoreData,
    axiosResponse,
    currPage,
    listRef,
    gridContainerRef,
  } = useInfinitePagination<User>({
    axiosConfig,
    initialPage: 1,
    limit: 18,
    isGridDisplay: displayGrid,
    ...paramsFromUrl,
  });

  const employees = useMemo(() => axiosResponse?.data.totalItems, [axiosResponse]);
  const activeEmployees = useMemo(() => axiosResponse?.data.totalActiveItems, [axiosResponse]);

  useEffect(() => {
    if (!urlParamsNames.searchParamName || debounceString === paramsFromUrl.searchString) return;

    if (!debounceString) searchParams.delete(urlParamsNames.searchParamName);
    else searchParams.set(urlParamsNames.searchParamName, debounceString);

    setSearchParams(searchParams);
    setSearch();
  }, [debounceString]);

  // Effect hook for detecting zoom changes
  useEffect(() => {
    const detectZoomChange = () => {
      const ratio = window.devicePixelRatio;
      setZoomLevel(Math.round(ratio * 100)); // Update state with the new zoom level
    };

    detectZoomChange(); // Initial check to set zoom level on component mount

    window.addEventListener('resize', detectZoomChange);

    return () => window.removeEventListener('resize', detectZoomChange);
  }, []);

  // manually load more data if the window is zoomed out, since scroll event won't work in that case
  useEffect(() => {
    if (zoomLevel < INITIAL_WINDOW_SIZE) {
      setTimeout(() => {
        loadMoreData();
      }, 500);
    }
  }, [zoomLevel]);

  useEffect(() => {
    const existingOverlay = document.querySelector('.overlay');
    if ((currPage === 1 || displayGrid) && loadingPagination) {
      if (!existingOverlay) OverlaySpinner.show('.panel');
    } else {
      OverlaySpinner.hide('.panel');
    }
  }, [currPage, loadingPagination]);

  useEffect(() => {
    setSearchString(searchParams.get(urlParamsNames.searchParamName) || '');
  }, [searchParams]);

  const handleSortEmployeesByName = (data: number) => {
    searchParams.set(urlParamsNames.sortByParamName, data.toString());

    setSearchParams(searchParams);
  };

  return (
    <div className='my-employees' onScroll={onScroll} ref={listRef}>
      <div className='my-employees__container' ref={listInnerRef}>
        <div className='my-employees__header'>
          <div className='my-employees__main'>
            <h1>
              Employees
              <p>
                <b>{activeEmployees || '-'}</b> employees
              </p>
            </h1>
            <Button icon={<AddIcon />} onClick={openPopup} hoverText='Create new employee'>
              Add employee
            </Button>
          </div>
          <div className='my-employees__search'>
            <div className='my-employees__searchbar'>
              <Input
                prefixNode={<SearchIcon />}
                value={searchString}
                onChange={(ev) => setSearchString(ev.target.value)}
                placeholder='Search for an employee...'
                className='search-input'
              />
            </div>
            <div className='my-employees__buttons'>
              <Button
                variant='icon'
                type='button'
                onClick={() => setDisplayGrid(false)}
                hoverText='Show table view'
                aria-label='Show table view'
                className={
                  !displayGrid
                    ? 'my-employees__buttons__button-active'
                    : 'my-employees__buttons__button-inactive'
                }
              >
                <TableIcon />
              </Button>
              <Button
                variant='icon'
                type='button'
                onClick={() => setDisplayGrid(true)}
                hoverText='Show grid view'
                aria-label='Show grid view'
                className={
                  displayGrid
                    ? 'my-employees__buttons__button-active'
                    : 'my-employees__buttons__button-inactive'
                }
              >
                <GridIcon />
              </Button>
            </div>
          </div>
        </div>
        <NewEmployeeModal onCloseModal={closePopup} isModalOpen={isPopupOpen} />
        <div className='my-employees__content'>
          {employees === 0 && debounceString === '' && !loadingPagination ? (
            <div className='my-employees__no-users'>
              <div className='my-employees__no-users__images'>
                <LazyImage
                  src='images/userDefault.png'
                  alt='user'
                  className='my-employees__no-users__images__image-1'
                />
                <LazyImage
                  src='images/userDefault.png'
                  alt='user'
                  className='my-employees__no-users__images__image-2'
                />
                <LazyImage
                  src='images/userDefault.png'
                  alt='user'
                  className='my-employees__no-users__images__image-3'
                />
              </div>
              <p className='my-employees__no-users__description'>
                Seems like you didn’t add your employees. Just click on
                <b>
                  <i> + Add employee </i>
                </b>
                <br /> to add the first one. Also, don’t forget to send them an invite to join you
                and
                <br /> access their profile.
              </p>
            </div>
          ) : employees === 0 && debounceString !== '' ? (
            <div className='my-employees__empty-search'>
              <NoSearchResultsIcon />
              There are no employees under that name.
            </div>
          ) : (
            <>
              {!displayGrid && (
                <div className='my-employees__layout'>
                  <div className='my-employees__layout__header'></div>
                  <EmployeesTable
                    employees={dataList}
                    onSortChange={handleSortEmployeesByName}
                    loading={loadingPagination && currPage > 1}
                    initialSort={paramsFromUrl.sortByName}
                  />
                </div>
              )}
              <div>
                {displayGrid && (
                  <EmployeesLayout containerRef={gridContainerRef} employees={dataList} />
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Employees;
