const APP_URI = {
  base: '/',
  login: '/login',
  register: '/register',
  home: '/home',
  profile: '/profile',
  editProfile: '/edit-profile',
  dashboard: '/dashboard',
  employees: '/employees',
  documents: '/documents',
  daysoff: '/daysoff',
  timeoffs: '/timeoffs',
  user: '/user',
  panel: '/panel',
  manageEmployees: 'manage-employees/:userId?',
  passwordRecovery: '/password-recover',
  passwordReset: '/password-reset',
  invitationError: '/invalid-invitation',
  setPassword: '/register',
  settings: '/settings',
  roles: '/roles',
};

export { APP_URI };
