const EMAIL_REGEX = new RegExp(
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@ncoded.solutions$/,
);

const LOWERCASE_REGEX = /^(?=.*[a-z]).*$/;

const UPPERCASE_REGEX = /^(?=.*[A-Z]).*$/;

const LETTERS_REGEX = /^(?=.*[A-Za-z]).*$/;

const NUMBER_REGEX = /^(?=.*[0-9]).*$/;

const PHONE_REGEX_381 = /^\+381\d{8,10}$/;
const PHONE_REGEX_NORMAL = /^0\d{8,10}$/;

const NAME_REGEX = /^(|[A-ZŠĐŽĆČ][a-zA-ZšđžćčŠĐŽĆČ\s]*)$/;

const PERSONAL_ID_REGEX = /^(0[1-9]|[12][0-9]|3[01])(0[1-9]|1[012])(0|9)[0-9]{8}$/;
// eslint-disable-next-line no-useless-escape
const SPECIAL_CHARACTER_REGEX = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

export {
  EMAIL_REGEX,
  LOWERCASE_REGEX,
  UPPERCASE_REGEX,
  NUMBER_REGEX,
  LETTERS_REGEX,
  PHONE_REGEX_381,
  PHONE_REGEX_NORMAL,
  SPECIAL_CHARACTER_REGEX,
  NAME_REGEX,
  PERSONAL_ID_REGEX,
};
