import { ChevronIcon, PenIcon } from 'assets';
import { Button } from 'components';
import EditNonWorkingDays from 'components/EditNonWorkingDays/EditNonWorkingDays.component';
import EmployeeContext from 'pages/EmployeeProfilePage/context/EmploeeContext';
import { mapUserToNonWorkingDays } from 'pages/NonWorkingDaysPage/utils/utils';
import { useContext, useState } from 'react';

const EmployeeNonWorkingDays = () => {
  const { employee, refreshEmployeeData } = useContext(EmployeeContext);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isContentOpen, setIsContentOpen] = useState(false);

  return (
    <EditNonWorkingDays
      data={mapUserToNonWorkingDays(employee)}
      variant='standard'
      isEditMode={isEditMode}
      isContentOpen={isContentOpen}
      onSuccess={refreshEmployeeData}
      onCancel={() => setIsEditMode(false)}
      employee={
        <div>
          <p
            className={`employee-non-working-days__${isContentOpen ? 'open' : 'closed'}`}
            onClick={() => setIsContentOpen(!isContentOpen)}
          >
            <Button
              variant='icon'
              type='button'
              onClick={() => setIsContentOpen(!isContentOpen)}
              aria-label='Toggle non-working days'
            >
              <ChevronIcon />
            </Button>
            Vacation days
          </p>
          {isContentOpen && employee.isActive && (
            <div>
              <Button
                variant='icon'
                type='button'
                onClick={() => setIsEditMode(true)}
                disabled={isEditMode}
                aria-label='Edit'
              >
                <PenIcon />
              </Button>
            </div>
          )}
        </div>
      }
    />
  );
};

export default EmployeeNonWorkingDays;
