import { API_PATH } from 'constant';
import { CurrencySettings } from 'models/Currency';
import { httpClient } from 'modules';

const getCurrencies = async () => {
  return httpClient.get<CurrencySettings>(API_PATH.CURRENCIES);
};

const updateCurrencies = async (currencies: CurrencySettings) => {
  return httpClient.post<CurrencySettings>(API_PATH.CURRENCIES, currencies);
};

const removeSecondaryCurrency = async () => {
  return httpClient.delete<CurrencySettings>(API_PATH.CURRENCIES);
};

export default {
  getCurrencies,
  updateCurrencies,
  removeSecondaryCurrency,
};
