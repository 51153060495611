import { CalendarIcon, Monitor, MyEmployeesIcon, SettingsIcon, User } from 'assets';
import { APP_URI } from 'config';
import { UserRole } from 'enums';

export const getSidebarMenuItems = (userRole?: UserRole) => {
  const commonItems = [
    {
      display: 'Profile',
      icon: <User className='image' />,
      to: `${APP_URI.profile}?tab=personal-information`,
    },
  ];

  const userItems = [
    {
      display: 'Documents',
      icon: <Monitor className='image' />,
      to: APP_URI.documents,
    },
    {
      display: 'Days off',
      icon: <CalendarIcon className='image' />,
      to: `${APP_URI.daysoff}?daysoffSort=-1&vacationsSort=-1`,
    },
  ];

  const adminItems = [
    {
      display: 'Dashboard ',
      icon: <Monitor className='image' />,
      to: APP_URI.dashboard,
    },

    {
      display: 'Time offs',
      icon: <CalendarIcon className='image' />,
      to: APP_URI.timeoffs,
    },
    {
      display: 'Employees',
      icon: <MyEmployeesIcon className='image' />,
      to: APP_URI.employees,
    },

    {
      display: 'Settings',
      icon: <SettingsIcon className='image' />,
      to: '/settings?tab=multipliers',
    },
  ];

  const superAdminItems = [
    {
      display: 'Admins',
      icon: <Monitor className='image' />,
      to: '/roles',
    },
  ];

  const sidebarNavItems =
    userRole === UserRole.ADMIN || userRole === UserRole.SUPER_ADMIN
      ? [
          ...adminItems.slice(0, adminItems.length - 1),
          ...(userRole === UserRole.SUPER_ADMIN ? superAdminItems : []),
          ...commonItems,
          adminItems[adminItems.length - 1],
        ]
      : [...userItems, ...commonItems];

  return sidebarNavItems;
};
