import { SortIcon, UnsortedIcon } from 'assets';
import { Button, DownloadFile } from 'components';
import { TableColumnItem } from 'components/Table/Table.component';
import { SortingOrder } from 'models/types';
import { VacationRequestData } from 'models/VacationRequest';
import DateService from 'services/Date.service';

export const useVacationsTableColumns = (
  handleSortByStartDate: () => void,
  sortByStartDate: number,
) => {
  const items: TableColumnItem<VacationRequestData>[] = [
    {
      columnName: 'type',
      columnLabel: 'Type',
      prepareCell: () => 'Vacation approval',
    },
    {
      columnLabel: (
        <p className={`start-date-sort-icon-${sortByStartDate}`}>
          Start date
          <Button
            variant='icon'
            type='button'
            onClick={handleSortByStartDate}
            aria-label='Sort by start date'
          >
            {sortByStartDate == SortingOrder.UNSORTED && <UnsortedIcon />}
            {sortByStartDate != SortingOrder.UNSORTED && <SortIcon />}
          </Button>
        </p>
      ),
      columnName: 'start-date',
      prepareCell: (data) => DateService.formatDate(data.startDate),
    },
    {
      columnLabel: 'End',
      columnName: 'end',
      prepareCell: (data) => DateService.formatDate(data.endDate),
    },
    {
      columnLabel: 'Days',
      columnName: 'days',
      prepareCell: (data) => data.workingDays,
    },
    {
      columnLabel: 'Status',
      columnName: 'status',
      prepareCell: (data) => data.status,
    },
    {
      columnLabel: 'Download',
      columnName: 'download',
      prepareCell: (data) => (
        <DownloadFile
          document={
            data.signedDocumentUrl
              ? { fileUrl: data.signedDocumentUrl, awsKey: data.signedDocumentAwsKey }
              : undefined
          }
        />
      ),
    },
  ];
  return items;
};
