import { SalaryDisplay } from 'components';
import { TableColumnItem } from 'components/Table/Table.component';
import { EmployeeDocument } from 'models/EmployeeDocument';
import EmployeeDocumentActions from 'pages/EmployeeProfilePage/components/EmploymentInformation/components/EmployeeDocumentActions';

import DateService from 'services/Date.service';

const useEmploymentInfoTableColumns = (): TableColumnItem<EmployeeDocument>[] => [
  {
    columnLabel: 'Type',
    prepareCell: (data) => data.type || '',
  },
  {
    columnLabel: 'Start',
    prepareCell: (data) => DateService.formatDate(data.startDate),
  },
  {
    columnLabel: 'End',
    prepareCell: (data) => (data.endDate ? DateService.formatDate(data.endDate) : ''),
  },
  {
    columnLabel: 'Net salary',
    prepareCell: ({ netSalary }) => <SalaryDisplay defaultAmount={netSalary?.amount} />,
  },
  {
    columnLabel: 'Gross salary',
    prepareCell: ({ grossSalary }) => <SalaryDisplay defaultAmount={grossSalary?.amount} />,
  },

  {
    columnLabel: 'Actions',
    columnName: 'actions',
    prepareCell: (data) => {
      return <EmployeeDocumentActions document={data} />;
    },
  },
  {
    columnLabel: 'Note',
    columnName: 'note',
    prepareCell: (data) => data.note || '',
  },
];

export default useEmploymentInfoTableColumns;
