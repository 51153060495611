import { AddIcon, ChevronIcon } from 'assets';
import { Button } from 'components';
import Table from 'components/Table';
import TablePagination from 'components/TablePagination';
import { SectionBlock } from 'components/layout/SectionBlock';
import { useAuth } from 'context';
import { UserRole } from 'enums';
import { useModalState } from 'hooks';
import { EmployeeDocument } from 'models/EmployeeDocument';
import { snackbar } from 'modules';
import EmployeeContext from 'pages/EmployeeProfilePage/context/EmploeeContext';
import useAdminEmploymentStore from 'pages/EmployeeProfilePage/store/useAdminEmploymentStore';
import TimeOffsTable from 'pages/TimeOffsPage/components/TimeOffsTable/TimeOffsTable.component';
import { createRef, useCallback, useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import useGlobalMiltipliersStore from 'store/useGlobalMiltipliersStore';
import EmployeeSalaryVariables from '../EmployeeSalaryVariables/EmployeeSalaryVariables.component';
import { DocumentModal } from './components/DocumentModal';
import EmployeeNonWorkingDays from './components/EmployeeNonWorkingDays/EmployeeNonWorkingDays.component';
import useEmploymentInfoTableColumns from './hooks/useEmploymentInfoTableColumns';
import { UpdateSalaryInfoModal } from './components/UpdateSalaryInfoModal';

import './EmploymentInformation.style.scss';

const pageParamName = 'documentPage';

const EmploymentInformation = () => {
  const { employee, updateEmployee } = useContext(EmployeeContext);
  const {
    openModal: openDocumentModal,
    isOpen: isDocumentModalOpen,
    closeModal: closeDocumentModal,
  } = useModalState();
  const {
    openModal: openUpdateSalaryInfoModal,
    isOpen: isUpdateSalaryInfoModalOpen,
    closeModal: closeUpdateSalaryInfoModal,
  } = useModalState();

  const { getDocuments, isLoading, documents, pagination, addDocumentApi, params } =
    useAdminEmploymentStore();

  const { multipliers } = useGlobalMiltipliersStore();
  const { user } = useAuth();

  const [documentsOpen, setDocumentsOpen] = useState(true);
  const [vacationsOpen, setVacationsOpen] = useState(true);

  const [searchParams, setSearchParams] = useSearchParams();
  const [addedDocument, setAddedDocument] = useState<EmployeeDocument | undefined>();
  const page = +(searchParams.get(pageParamName) || '');

  const salaryCardRef = createRef<HTMLDivElement>();

  useEffect(() => {
    if (employee?.id) getDocuments(employee.id, { page });
  }, [employee?.id, page]);

  const columns = useEmploymentInfoTableColumns();

  const changePageNumber = useCallback(
    (page: number) => {
      if (employee?.id) {
        searchParams.set(pageParamName, page.toString());
        setSearchParams(searchParams);
      }
    },
    [employee?.id],
  );

  const onAddSuccess = useCallback(
    (document: EmployeeDocument) => {
      snackbar.show({
        message: 'Document saved',
        type: 'success',
      });
      closeDocumentModal();

      if (document.netSalary && document.multiplierId && !employee?.netSalary) {
        setAddedDocument(document);
        openUpdateSalaryInfoModal();
      }
    },
    [closeDocumentModal, employee, document],
  );

  const onAddError = useCallback((errorMessage: string) => {
    snackbar.show({ message: errorMessage, type: 'error' });
  }, []);

  const handleAddDocument = useCallback(
    (formData: FormData) => {
      if (employee?.id) return addDocumentApi(employee?.id, formData, onAddSuccess, onAddError);
    },
    [employee?.id, onAddSuccess, onAddError],
  );

  return (
    <div className='employment-info'>
      <SectionBlock
        title={
          <p
            className={`employment-info__documents-section__accordion__${documentsOpen ? 'open' : 'closed'}`}
            onClick={() => setDocumentsOpen(!documentsOpen)}
          >
            <Button
              variant='icon'
              type='button'
              onClick={() => setDocumentsOpen(!documentsOpen)}
              aria-label='Employment documents'
            >
              <ChevronIcon />
            </Button>
            Employment information documents
          </p>
        }
        loading={isLoading}
        className='employment-info__documents-section'
        asideFromTitle={
          documentsOpen &&
          employee.isActive && (
            <Button onClick={openDocumentModal} className='employment-info__add-button'>
              <AddIcon />
              Add document
            </Button>
          )
        }
        displayChildren={documentsOpen}
      >
        <div className='employment-info__table-container'>
          <Table<EmployeeDocument>
            data={documents}
            prepareData={columns}
            noDataMessage={
              user?.role === UserRole.ADMIN
                ? "The employee doesn't have any documents uploaded yet. 📄"
                : "Welcome to Ncoded! 👋 Here you'll find your employment related documents once the admin adds them."
            }
          />
        </div>

        {pagination?.totalItems > 0 && (
          <TablePagination
            totalPages={pagination?.totalPages}
            currentPage={pagination?.currentPage}
            onPageChange={changePageNumber}
            totalItems={pagination?.totalItems}
            pageSize={params.limit}
          />
        )}
        {isDocumentModalOpen && (
          <DocumentModal
            isOpen={isDocumentModalOpen}
            onRequestClose={closeDocumentModal}
            userId={employee?.id || ''}
            multipliers={multipliers}
            onSubmit={handleAddDocument}
          />
        )}
      </SectionBlock>
      <EmployeeNonWorkingDays />
      <div className='employment-info__timeoffs-table'>
        <h1
          className={`employment-info__timeoffs-table__accordion--${vacationsOpen ? 'open' : 'closed'}`}
          onClick={() => setVacationsOpen(!vacationsOpen)}
        >
          <Button
            variant='icon'
            type='button'
            onClick={() => setVacationsOpen(!vacationsOpen)}
            aria-label='Toggle vacations requests'
          >
            <ChevronIcon />
          </Button>
          Vacation requests
        </h1>
        {vacationsOpen && (
          <TimeOffsTable
            isSearchVisible={false}
            initialStatus='Submitted'
            urlParamNames={{
              pageParamName: 'page',
              searchParamName: 'search',
              sortByRequestParamName: 'sortByRequest',
              sortByStartDateParamName: 'sortByDate',
              statusParamName: 'status',
            }}
            profileImageVisible={false}
          />
        )}
      </div>
      <EmployeeSalaryVariables containerRef={salaryCardRef} />
      <UpdateSalaryInfoModal
        isOpen={isUpdateSalaryInfoModalOpen}
        closeModal={closeUpdateSalaryInfoModal}
        document={addedDocument}
        onSuccess={() => {
          salaryCardRef.current?.scrollIntoView();
        }}
      />
    </div>
  );
};

export default EmploymentInformation;
