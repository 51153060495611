import { FC } from 'react';
import { Button } from 'components/Button';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { MSG_REQUIRED_FIELD } from 'validation';
import { FieldWrapper } from 'components/FieldWrapper';
import Textarea, { TextareaProps } from 'components/Textarea/Textarea.components';

import './RequestPreview.styles.scss';

type RequestPreviewProps = {
  emailPreview: string;
  onBackBtnClicked: () => void;
  onSubmit: (emailContent: string) => void;
  requestLoading?: boolean;
};

type RequestPreviewType = {
  emailContent: string;
};

const validation = () =>
  Yup.object().shape({
    emailContent: Yup.string()
      .max(1200, 'Reason must be at most 1200 characters')
      .required(MSG_REQUIRED_FIELD),
  });

const RequestPreview: FC<RequestPreviewProps> = ({
  emailPreview,
  onBackBtnClicked,
  onSubmit,
  requestLoading,
}) => {
  const methods = useForm<RequestPreviewType>({
    mode: 'onChange',
    resolver: yupResolver(validation()),
    defaultValues: { emailContent: emailPreview },
  });

  return (
    <div className='request-preview'>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit((e) => onSubmit(e.emailContent))}>
          <p className='request-preview__description'>
            Please review the request and make sure you have entered the right information.{' '}
          </p>
          <FieldWrapper<TextareaProps>
            name='emailContent'
            placeholder='Enter message...'
            fieldComponent={Textarea}
            rows={16}
            className='request-preview__email-content'
          />
          <div className='request-preview__btns-container'>
            <Button type='button' variant='outline' onClick={onBackBtnClicked}>
              Back
            </Button>
            <Button type='submit' loading={requestLoading}>
              Send
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default RequestPreview;
