import { FC } from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { FieldWrapper } from 'components/FieldWrapper';
import { DatePicker } from 'components/DatePicker';
import { Textarea } from 'components/Textarea';
import { TextareaProps } from 'components/Textarea/Textarea.components';
import { DatePickerProps } from 'components/DatePicker/DatePicker.component';
import { Button } from 'components/Button';
import { RequestVacationType } from 'components/RequestVacationModal/EmployeeRequestVacation.modal';
import Input, { InputProps } from 'components/Input/Input.component';

import './EmployeeRequestVacationForm.styles.scss';
import classNames from 'classnames';

type EmployeeRequestVacationFormProps = {
  methods: UseFormReturn<any, any, undefined>;
  onSubmit: (employeeRequestVacation: RequestVacationType) => void;
  submitLoading?: boolean;
};

const EmployeeRequestVacationForm: FC<EmployeeRequestVacationFormProps> = (props) => {
  const { methods, onSubmit, submitLoading } = props;

  return (
    <FormProvider {...methods}>
      <p className='employee-request-vacation-modal__container__notiffication'>
        Before requesting vacation please make sure that you have consulted with your management
        team and teammates, esspecially if you plan to take a longer break from work 🌴
      </p>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        className={'ne-employee-request-vacation-form'}
      >
        <div className='ne-employee-request-vacation-form__fields-container'>
          <div
            className={classNames('document-form__row', 'ne-employee-request-vacation-form__dates')}
          >
            <FieldWrapper<DatePickerProps>
              name='startDate'
              label='Start Date'
              fieldComponent={DatePicker}
              asterix
              placeholder='dd/mm/yyyy'
            />
            <FieldWrapper<DatePickerProps>
              name='endDate'
              label='End Date'
              fieldComponent={DatePicker}
              asterix
              placeholder='dd/mm/yyyy'
            />
          </div>
          <FieldWrapper<InputProps>
            name='immediateSupervisor'
            label='Immediate supervisor'
            fieldComponent={Input}
            placeholder='Enter name and last name'
            description='The person who you have consulted with and approved requesting vacation.'
            required
            asterix
          />
          <FieldWrapper<InputProps>
            name='substitute'
            label='Vacation substitute (optional)'
            fieldComponent={Input}
            placeholder='Enter name and last name'
            description='The person who will cover for you or be your point of contact during your vacation.'
          />
          <FieldWrapper<TextareaProps>
            name='reason'
            label='Reason (optional)'
            placeholder='Enter reason...'
            fieldComponent={Textarea}
          />
        </div>
        <Button
          className='ne-employee-request-vacation-form__button'
          type='submit'
          loading={submitLoading}
          disabled={submitLoading}
        >
          Next
        </Button>
      </form>
    </FormProvider>
  );
};

export default EmployeeRequestVacationForm;
