import { SearchIcon } from 'assets';
import { Input } from 'components';
import { SectionBlock } from 'components/layout/SectionBlock';
import Table from 'components/Table';
import TablePagination from 'components/TablePagination';
import TabsV2 from 'components/TabsV2/TabsV2.component';
import { API_PATH } from 'constant';
import { useAxios, useModalState } from 'hooks';
import useDebounce from 'hooks/useDebounce';
import { Pagination, PaginationType } from 'models/types';
import { VacationRequestData } from 'models/VacationRequest';
import { snackbar } from 'modules';
import EmployeeContext from 'pages/EmployeeProfilePage/context/EmploeeContext';
import useStatusFilterItems from 'pages/TimeOffsPage/hooks/useStatusFilterItems';
import { useTimeOffTableColumns } from 'pages/TimeOffsPage/hooks/useTimeOffTableColumns';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { generateUrl } from 'utils/generateUrl';
import { VacationRequestModal } from '../VacationRequestModal';
import { mapUrlParamsToRequestParams } from './utils/utils';

import './TimeOffsTable.styles.scss';

type ErrorType = {
  message: string;
  error: string;
  statusCode: number;
};

export type TimeOffStatus = 'Submitted' | 'Approved' | 'Fulfilled' | 'Denied';

export type TimeOffsParams = {
  search: string;
  page: number;
  limit: number;
  filter: {
    status?: TimeOffStatus;
    userId?: string;
  };
};

export type TimeOffsUrlParamNames = {
  pageParamName: string;
  statusParamName?: string;
  searchParamName?: string;
  sortByRequestParamName: string;
  sortByStartDateParamName: string;
};

type TimeOffsTableProps = {
  isSearchVisible?: boolean;
  areTabsVisible?: boolean;
  initialStatus?: string;
  urlParamNames: TimeOffsUrlParamNames;
  profileImageVisible?: boolean;
};

const TimeOffsTable: React.FC<TimeOffsTableProps> = (props) => {
  const {
    isSearchVisible = true,
    areTabsVisible = true,
    initialStatus = '',
    urlParamNames,
    profileImageVisible = true,
  } = props;

  const { employee } = useContext(EmployeeContext);
  const [selectedVacation, setSelectedVacation] = useState<VacationRequestData | undefined>();
  const [data, setData] = useState<VacationRequestData[]>([]);
  const [pagination, setPagination] = useState<Pagination>({} as Pagination);

  const [searchParams, setSearchParams] = useSearchParams();

  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();

  const sortByDate = +(searchParams.get(urlParamNames.sortByStartDateParamName) || '');
  const sortByRequest = +(searchParams.get(urlParamNames.sortByRequestParamName) || -1);
  const search =
    (urlParamNames.searchParamName && searchParams.get(urlParamNames.searchParamName)) || '';

  const getInitialParams = useCallback(
    () => ({
      limit: 10,
      page: 1,
      search: '',
      sort: { createdAt: -1 },
      ...(initialStatus && { filter: { status: initialStatus as TimeOffStatus } }),
    }),
    [employee],
  );

  const params = useMemo(() => {
    const initParams = getInitialParams();
    const paramsFromUrl = mapUrlParamsToRequestParams(urlParamNames, searchParams);
    let params = { ...initParams, ...paramsFromUrl };
    const { filter } = params;
    if (!isSearchVisible) params = { ...params, filter: { ...filter, userId: employee.id } };
    if (!areTabsVisible) params = { ...params, filter: initParams.filter };

    return params;
  }, [searchParams]);

  const [searchString, setSearchString] = useState(search);

  const {
    isOpen: isVacationRequestModalOpen,
    openModal: openVacationRequestModal,
    closeModal: closeVacationRequestModal,
  } = useModalState();

  const { request: getVacationRequest, loading: loadingVacation } = useAxios<
    VacationRequestData,
    ErrorType
  >({
    url: `${API_PATH.VACATIONS}/${id}`,
    method: 'GET',

    onResponse: (response) => {
      const { data } = response;
      setSelectedVacation(data);
    },
  });

  const { request: getVacationRequests, loading: loadingVacations } = useAxios<
    PaginationType<VacationRequestData>,
    ErrorType
  >({
    url: generateUrl(API_PATH.VACATIONS, params),
    method: 'GET',
    params,
    onResponse: (response) => {
      const { data } = response;
      const { items, ...rest } = data;

      if (employee?.id) {
        setData(items.filter((item) => item.userId == employee?.id));
      } else {
        setData(items);
      }

      if (rest.currentPage !== 1 && rest.currentPage > rest.totalPages) {
        searchParams.delete(urlParamNames.pageParamName);
        setSearchParams(searchParams);
      }
      setPagination(rest as Pagination);
    },
  });

  const handleOnRowClick = useCallback(
    (data: VacationRequestData) => {
      navigate(`${data._id}${location.search}`);
      setSelectedVacation(data);
    },
    [openVacationRequestModal, location],
  );

  const handleSortByRequestedOn = () => {
    searchParams.set(
      urlParamNames.sortByRequestParamName,
      !sortByRequest ? '-1' : (-sortByRequest).toString(),
    );
    searchParams.delete(urlParamNames.sortByStartDateParamName);
    setSearchParams(searchParams);
  };

  const handleSortByStartDate = () => {
    searchParams.set(
      urlParamNames.sortByStartDateParamName,
      !sortByDate ? '-1' : (-sortByDate).toString(),
    );
    searchParams.delete(urlParamNames.sortByRequestParamName);
    setSearchParams(searchParams);
  };

  const updateTimeOffsData = () => {
    getVacationRequests({});
  };

  const { request: rejectRequest } = useAxios({
    url: `vacations/${selectedVacation?._id}/reject`,
    method: 'POST',
    overlayClass: 'submitted-request-modal',
    onResponse: () => {
      updateTimeOffsTabs();
      updateTimeOffsData();
      closeVacationRequestModal();
      snackbar.show({
        message: 'You have successfully rejected vacation request.',
        type: 'success',
      });
    },
    onError: (error) => {
      snackbar.show({ message: error.message, type: 'error' });
    },
  });

  const { request: approveRequest } = useAxios({
    url: `vacations/${selectedVacation?._id}/approve`,
    method: 'POST',
    overlayClass: 'submitted-request-modal',
    onResponse: () => {
      updateTimeOffsTabs();
      updateTimeOffsData();
      closeVacationRequestModal();
      snackbar.show({
        message:
          'You have approved the request. The administrator will receive a notification to prepare written approval.',
        type: 'success',
      });
    },
    onError: (error) => {
      snackbar.show({ message: error.message, type: 'error' });
    },
  });

  const columns = useTimeOffTableColumns(
    handleOnRowClick,
    handleSortByStartDate,
    handleSortByRequestedOn,
    sortByDate,
    sortByRequest,
    (userId: string) => navigate(`/employees/manage-employees/${userId}${location.search}`),
    profileImageVisible,
  );
  const { statusFilterItems, updateTimeOffsTabs } = useStatusFilterItems({
    ...params,
    ...(!isSearchVisible && employee?.id && { userId: employee?.id }),
  });

  const onSearchStringChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (isSearchVisible) setSearchString(e.target.value);
  }, []);

  const debouncedSearchString = useDebounce(searchString, 500);

  const changePageNumber = (pageNumber: number) => {
    searchParams.set(urlParamNames.pageParamName, pageNumber.toString());
    setSearchParams(searchParams);
  };

  const onStatusChange = useCallback(
    (id: string) => {
      changePageNumber(1);
      setPagination((prev) => ({ ...prev, currentPage: 1 }));

      urlParamNames.statusParamName && searchParams.set(urlParamNames.statusParamName, id);
      setSearchParams(searchParams);
    },
    [pagination],
  );

  useEffect(() => {
    if (!urlParamNames.searchParamName || debouncedSearchString === search) return;

    if (!debouncedSearchString) searchParams.delete(urlParamNames.searchParamName);
    else searchParams.set(urlParamNames.searchParamName, debouncedSearchString);

    searchParams.delete(urlParamNames.pageParamName);

    setSearchParams(searchParams);
  }, [debouncedSearchString]);

  useEffect(() => {
    updateTimeOffsTabs();
    getVacationRequests({});
  }, [params]);

  useEffect(() => {
    if (selectedVacation) {
      openVacationRequestModal();
      setSelectedVacation(selectedVacation);
    }
  }, [selectedVacation]);

  useEffect(() => {
    if (!id) return;

    const vacationFromTable = data.find((vacation) => vacation._id === id);
    if (vacationFromTable) setSelectedVacation(vacationFromTable);
    else getVacationRequest({});
  }, [id]);

  const noTableDataMessage = useMemo(() => {
    return `There are no ${(params.filter?.status as string) ? params.filter?.status?.toLowerCase() : ''} ${
      params.search
        ? 'requests for time off from the employee under that name.'
        : 'requests for vacation.'
    }`;
  }, [params]);

  return (
    <div className='ne-timeoffs-table'>
      {selectedVacation && (
        <VacationRequestModal
          isOpen={isVacationRequestModalOpen}
          onClose={() => {
            setSelectedVacation(undefined);
            const pathName = location.pathname.split('/');
            navigate(
              `/${pathName[1]}${pathName[1] === 'employees' ? `/${pathName[2]}/${pathName[3]}` : ''}${location.search}`,
            );

            closeVacationRequestModal();
          }}
          request={selectedVacation}
          onSuccess={() => {
            updateTimeOffsTabs();
            updateTimeOffsData();
          }}
          status={selectedVacation.status}
          title={selectedVacation.status + ' vacation request'}
          approveVacationRequest={() => approveRequest({})}
          rejectVacationRequest={() => rejectRequest({})}
        />
      )}

      <SectionBlock loading={loadingVacations} className='ne-timeoffs-table__data'>
        <div className='ne-timeoffs-table__filters'>
          {areTabsVisible && (
            <TabsV2
              items={statusFilterItems}
              onFilterSelect={onStatusChange}
              selectedItem={params.filter?.status as string}
            />
          )}
          {isSearchVisible && (
            <Input
              prefixNode={<SearchIcon />}
              value={searchString}
              onChange={onSearchStringChange}
              placeholder='Search for an employee...'
            />
          )}
        </div>

        <div className='ne-timeoffs-table__table-container'>
          <Table
            data={data.map((request) => ({
              ...request,
              userDeactivated: employee && !employee?.isActive,
            }))}
            prepareData={columns}
            noDataMessage={noTableDataMessage}
          />
        </div>

        <TablePagination
          totalPages={pagination?.totalPages}
          currentPage={pagination?.currentPage}
          onPageChange={changePageNumber}
          totalItems={pagination?.totalItems}
          pageSize={pagination?.pageSize}
        />
      </SectionBlock>
    </div>
  );
};

export default TimeOffsTable;
