import { Button, DocumentDropzone } from 'components';
import { DocumentDropzoneProps } from 'components/DocumentDropzone/DocumentDropzone.component';
import { UploadFile } from 'components/Dropzone';
import { FieldWrapper } from 'components/FieldWrapper';
import { VacationRequestData } from 'models/VacationRequest';
import { FC, useState } from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import DateService from 'services/Date.service';
import { CheckIcon, DeniedIcon, DoubleCheckIcon, DownloadIcon } from 'assets';
import { useLocation, useNavigate, Link } from 'react-router-dom';

import './VacationRequestForm.styles.scss';

type VacationRequestValue = {
  file: UploadFile[];
};

type VacationRequestFormProps = {
  request: VacationRequestData;
  methods: UseFormReturn<VacationRequestValue, any, undefined>;
  onSubmit: (formValue: VacationRequestValue) => void;
  approveVacationRequest?: () => void;
  rejectVacationRequest?: () => void;
  isLoading: boolean;
  status: string;
  title: string;
};
const VacationRequestForm: FC<VacationRequestFormProps> = (props) => {
  const [isApprovedOrRejected, setIsApprovedOrRejected] = useState(false);

  const getModalIcon = () => {
    switch (status) {
      case 'Fulfilled':
        return (
          <div className='fulfilled-icon'>
            <DoubleCheckIcon />
          </div>
        );
      case 'Approved':
        return (
          <div className='approved-icon'>
            <CheckIcon />
          </div>
        );
      case 'Denied':
        return (
          <div className='denied-icon'>
            <DeniedIcon />
          </div>
        );
    }
  };
  const {
    methods,
    request,
    title,
    onSubmit,
    approveVacationRequest,
    rejectVacationRequest,
    isLoading,
    status,
  } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const handleApprove = () => {
    if (approveVacationRequest) {
      approveVacationRequest();
      const pathName = location.pathname.split('/');
      navigate(
        `/${pathName[1]}${pathName[1] === 'employees' ? `/${pathName[2]}/${pathName[3]}` : ''}${location.search}`,
      );
      setIsApprovedOrRejected(true);
    }
  };

  const handleReject = () => {
    if (rejectVacationRequest) {
      rejectVacationRequest();
      const pathName = location.pathname.split('/');
      navigate(
        `/${pathName[1]}${pathName[1] === 'employees' ? `/${pathName[2]}/${pathName[3]}` : ''}${location.search}`,
      );
      setIsApprovedOrRejected(true);
    }
  };

  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className='vacation-request'>
            <h1>
              {getModalIcon()} {title}
            </h1>
            <div className='vacation-request__content'>
              <dl className='vacation-request__subsection'>
                <dt>Employee</dt>
                <dd>
                  <b>
                    {request.user.firstName} {request.user.lastName}
                  </b>
                </dd>
              </dl>

              <dl className='vacation-request__dates'>
                <div className='vacation-request__subsection'>
                  <dt>From</dt>
                  <dd>
                    <b>{DateService.formatDate(request.startDate)}</b>
                  </dd>
                </div>
                <span>-</span>
                <div className='vacation-request__subsection'>
                  <dt>To</dt>
                  <dd>
                    <b>{DateService.formatDate(request.endDate)}</b>
                  </dd>
                </div>
              </dl>

              <dl className='vacation-request__subsection'>
                <div>
                  <dt>Immediate supervisor</dt>
                  <dd>{request.immediateSupervisor || 'Not provided'}</dd>
                </div>
                <div>
                  <dt>Vacation substitute</dt>
                  <dd>{request.substitute || 'Not provided'}</dd>
                </div>
                <div>
                  <dt>Reason</dt>
                  <dd>{request.description || 'Not provided'}</dd>
                </div>
              </dl>

              <div className='vacation-request__files'>
                {status &&
                  (status === 'Fulfilled' || status === 'Pending signing') &&
                  request.documentUrl && (
                    <dl className='vacation-request__subsection'>
                      <dt>Draft File</dt>
                      <dd>
                        <Link to={request.documentUrl}>
                          Download
                          <DownloadIcon />
                        </Link>
                      </dd>
                    </dl>
                  )}
                {status && status === 'Fulfilled' && request.signedDocumentUrl && (
                  <dl className='vacation-request__subsection'>
                    <dt>Signed File</dt>
                    <dd>
                      <Link to={request.signedDocumentUrl}>
                        Download
                        <DownloadIcon />
                      </Link>
                    </dd>
                  </dl>
                )}
              </div>
            </div>
            {status && (status === 'Approved' || status === 'Pending signing') && (
              <div className='vacation-request__upload'>
                <FieldWrapper<DocumentDropzoneProps>
                  name='file'
                  fieldComponent={DocumentDropzone}
                  className='vacation-request__dropzone'
                />
                {methods.formState.errors.root && (
                  <small className='vacation-request__error-msg'>
                    {methods.formState.errors.root?.message}
                  </small>
                )}

                <div>
                  <Button
                    disabled={!methods.watch('file') || methods.watch('file').length === 0}
                    loading={isLoading}
                    type='submit'
                  >
                    Save
                  </Button>
                </div>
              </div>
            )}
          </div>
        </form>
      </FormProvider>
      {status && status === 'Submitted' && (
        <div className='vacation-request__buttons'>
          <Button
            onClick={handleReject}
            type='button'
            disabled={isApprovedOrRejected}
            className={
              isApprovedOrRejected
                ? 'vacation-request__buttons--disabled'
                : 'vacation-request__buttons--reject'
            }
          >
            Reject
          </Button>
          <Button
            onClick={handleApprove}
            type='button'
            disabled={isApprovedOrRejected}
            className={
              isApprovedOrRejected
                ? 'vacation-request__buttons--disabled'
                : 'vacation-request__buttons--approve'
            }
          >
            Approve
          </Button>
        </div>
      )}
    </div>
  );
};

export default VacationRequestForm;
