const USER_BASE_PATH = '/users';
const USER_PASSWORD_PATH = `${USER_BASE_PATH}/password`;
const USER_IMAGE_PATH = `${USER_BASE_PATH}/image`;
const VACATION_BASE_PATH = '/vacations';
const GENERAL_VACATION_BASE_PATH = '/general-vacations';

const API_PATH = {
  LOGIN: `${USER_BASE_PATH}/login`,
  FORGOT_PASSWORD: `${USER_PASSWORD_PATH}/forgot`,
  RESET_PASSWORD: `${USER_PASSWORD_PATH}/reset`,
  CHANGE_PASSWORD: `${USER_PASSWORD_PATH}/change`,
  CURRENT_USER: `${USER_BASE_PATH}/current`,
  USERS: `${USER_BASE_PATH}`,
  INVITE_USER: `${USER_BASE_PATH}/invite`,
  REGISTER_USER: `${USER_BASE_PATH}/register`,
  VACATION_COUNTS: `${VACATION_BASE_PATH}/counts`,
  REMOVE_USER_IMAGE: `${USER_IMAGE_PATH}/remove`,
  UPLOAD_USER_IMAGE: `${USER_IMAGE_PATH}/upload`,
  VACATIONS: `${VACATION_BASE_PATH}`,
  GENERAL_VACATIONS: `${GENERAL_VACATION_BASE_PATH}`,
  UPLOAD_USER_IMAGE_BY_ADMIN: (userId: string) => `${USER_BASE_PATH}/${userId}/image/upload`,
  REMOVE_USER_IMAGE_BY_ADMIN: (userId: string) => `${USER_BASE_PATH}/${userId}/image/remove`,
  DEACTIVATE_USER: `${USER_BASE_PATH}/deactivate`,
  REACTIVATE_USER: `${USER_BASE_PATH}/reactivate`,
  REMOVE_USER: `${USER_BASE_PATH}`,
  GET_ADMINS: `${USER_BASE_PATH}/admins`,
  ADD_ADMIN: `${USER_BASE_PATH}/admin`,
  INVITE_ADMIN: (adminId: string) => `${USER_BASE_PATH}/admin/invite/${adminId}`,
  DELETE_ADMIN_INVITATION: (adminId: string) => `${USER_BASE_PATH}/${adminId}`,
  CURRENCIES: '/currency-exchanges',
};
export { API_PATH };
