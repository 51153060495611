import { yupResolver } from '@hookform/resolvers/yup';
import { Modal } from 'components/Modal';
import { API_PATH } from 'constant';
import { useAxios } from 'hooks';
import { snackbar } from 'modules';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { MSG_REQUIRED_FIELD, NAME_REGEX } from 'validation';
import * as Yup from 'yup';
import { EmployeeRequestVacationForm } from './components/EmployeeRequestVacationForm';
import { RequestPreview } from './components/RequestPreview';
import { generateVacationRequestEmailText } from './utils';
import DateService from 'services/Date.service';
import { useAuth } from 'context';
import { MSG_NAME_INVALID } from 'validation/messages';
import { pickBy } from 'lodash';

import './EmployeeRequestVacation.styles.scss';

type RequestVacationModalProps = {
  isOpen: boolean;
  onRequestClose: () => void;
  onSucces?: () => void;
};

export type RequestVacationType = {
  startDate: Date | string;
  endDate: Date | string;
  immediateSupervisor: string;
  substitute?: string | undefined;
  reason?: string;
};

export type RequestVacationPayload = {
  startDate: Date | string;
  endDate: Date | string;
  immediateSupervisor: string;
  substitute?: string;
  description?: string;
  emailContent: string;
};

type ErrorResponseType = {
  message: string;
};

const validation = () =>
  Yup.object().shape({
    startDate: Yup.mixed<Date | string>()
      .required(MSG_REQUIRED_FIELD)
      .test('isStartDateValid', 'Start date cannot be today', (value) => {
        const selectedDate = new Date(value);
        const currentDate = new Date();

        return selectedDate > currentDate;
      })
      .test('isNotWeekend', 'Weekend days cannot be selected', (value) => {
        const selectedDate = new Date(value);
        const dayOfWeek = selectedDate.getDay(); // 0 for Sunday, 6 for Saturday

        return dayOfWeek !== 0 && dayOfWeek !== 6; // Return true if not Sunday or Saturday
      }),
    endDate: Yup.mixed<Date | string>()
      .required(MSG_REQUIRED_FIELD)
      .test('isEndDateValid', 'End date must be after or equal to start date', function (value) {
        const startDateValue = this.parent.startDate;

        if (!startDateValue) return true;

        const startDateObject = new Date(startDateValue);
        const endDateObject = new Date(value);

        return endDateObject >= startDateObject;
      })
      .test('isNotWeekend', 'Weekend days cannot be selected', (value) => {
        const selectedDate = new Date(value);
        const dayOfWeek = selectedDate.getDay(); // 0 for Sunday, 6 for Saturday
        return dayOfWeek !== 0 && dayOfWeek !== 6; // Return true if not Sunday or Saturday
      }),
    immediateSupervisor: Yup.string()
      .matches(NAME_REGEX, MSG_NAME_INVALID)
      .min(3, 'Must be at least 3 characters')
      .max(60, 'Must be at most 60 characters')
      .required(MSG_REQUIRED_FIELD),

    substitute: Yup.string().matches(NAME_REGEX, MSG_NAME_INVALID).max(60),

    reason: Yup.string().max(600, 'Reason must be at most 600 characters'),
  });

const EmployeeRequestVacation = (props: RequestVacationModalProps) => {
  const { isOpen, onRequestClose, onSucces } = props;

  const [step, setStep] = useState(0);
  const [requestData, setRequestData] = useState<RequestVacationType>();
  const { user } = useAuth();

  const methods = useForm<RequestVacationType>({
    mode: 'onChange',
    resolver: yupResolver(validation()),
  });

  const emailPreview = useMemo(
    () =>
      requestData
        ? generateVacationRequestEmailText({
            ...requestData,
            employeeName: `${user?.firstName} ${user?.lastName}`,
          })
        : '',
    [requestData, user],
  );

  const { request: addRequestVacation, loading: requestVacationLoading } = useAxios<
    any,
    ErrorResponseType,
    RequestVacationPayload
  >({
    url: API_PATH.VACATIONS,
    method: 'POST',
    onResponse: () => {
      closeModal();
      onSucces?.();
      snackbar.show({ message: 'You have successfully sent vacation request.', type: 'success' });
    },
    onError: (error) => {
      snackbar.show({ message: error.response?.data.message || error.message, type: 'error' });
    },
  });

  const handleSubmit = useCallback(
    (ev: RequestVacationType) => {
      setStep(1);
      setRequestData(pickBy(ev, (value) => !!value) as RequestVacationType);
    },
    [setStep, setRequestData],
  );

  const sendRequest = useCallback(
    (emailContent: string) => {
      if (!requestData) return;

      addRequestVacation({
        payload: {
          ...requestData,
          startDate: DateService.formatDate(requestData.startDate, 'yyyy-MM-dd'),
          endDate: DateService.formatDate(requestData.endDate, 'yyyy-MM-dd'),
          ...(requestData.reason && { description: requestData.reason }),
          emailContent,
        },
      });
    },
    [requestData, addRequestVacation],
  );

  const closeModal = useCallback(() => {
    onRequestClose();
    methods.reset();
    setStep(0);
  }, [onRequestClose, methods.reset]);

  const goBack = useCallback(() => setStep(0), [setStep]);

  const startDate = methods.watch('startDate');
  const endDate = methods.watch('endDate');

  useEffect(() => {
    if (startDate && endDate && new Date(startDate) > new Date(endDate)) {
      methods.setError('endDate', {
        type: 'manual',
        message: 'End date cannot be before start date.',
      });
    } else {
      methods.clearErrors('endDate');
    }
  }, [startDate, endDate, methods]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      hasCloseIcon
      className='employee-request-vacation-modal'
    >
      <div className='employee-request-vacation-modal__container'>
        <h1 className='employee-request-vacation-modal__container__title'>Request vacation</h1>
        {!step ? (
          <EmployeeRequestVacationForm
            onSubmit={handleSubmit}
            methods={methods}
            submitLoading={requestVacationLoading}
          />
        ) : (
          <RequestPreview
            emailPreview={emailPreview}
            onBackBtnClicked={goBack}
            onSubmit={sendRequest}
            requestLoading={requestVacationLoading}
          />
        )}
      </div>
    </Modal>
  );
};

export default EmployeeRequestVacation;
