import { PageHeader } from 'components/layout/PageHeader';
import { PageLayout } from 'components/layout/PageLayout';
import TimeOffsTable from './components/TimeOffsTable/TimeOffsTable.component';
import { NonWorkingDaysPage } from 'pages/NonWorkingDaysPage';
import { Tabs } from 'components';

import './TimeOffsPage.styles.scss';

type TimeOffStatus = 'Submitted' | 'Approved' | 'Fulfilled' | 'Denied';
export type TimeOffsParams = {
  search: string;
  page: number;
  limit: number;
  filter: { status?: TimeOffStatus };
};

const tabsData = [
  {
    label: <div>Vacation requests</div>,
    content: (
      <TimeOffsTable
        initialStatus='Submitted'
        urlParamNames={{
          pageParamName: 'page',
          searchParamName: 'search',
          sortByRequestParamName: 'sortByRequest',
          sortByStartDateParamName: 'sortByDate',
          statusParamName: 'status',
        }}
      />
    ),
    id: 'vacation-requests',
  },
  {
    label: <div>General and personal vacations</div>,
    content: <NonWorkingDaysPage />,
    id: 'general-and-personal-vacations',
  },
];

const TimeOffsPage = () => {
  return (
    <PageLayout className='ne-timeoffs'>
      <PageHeader title='Time offs' className='ne-timeoffs__page-header'></PageHeader>
      <Tabs tabs={tabsData} />
    </PageLayout>
  );
};

export default TimeOffsPage;
