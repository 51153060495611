import { useCallback, useEffect, useMemo } from 'react';
import { SUPPORTED_CURRENCIES } from 'pages/SettingsPage/consts/currency';
import SelectV2 from 'components/SelectV2';
import { Button, Loader, Modal } from 'components';
import { PenIcon } from 'assets';
import { useModalState } from 'hooks';
import ExchangeRateModal from './components/ExchangeRateModal';
import useCurrenciesStore from 'store/useCurrenciesStore';
import { Currency } from 'models/Currency';
import { snackbar } from 'modules';
import { ExchangeFormValue } from './components/ExchangeRateModal/ExchangeRateModal.component';
import RemoveSecondaryCurrencyModal from './components/RemoveSecondaryCurrencyModal';
import { MSG_REQUIRED_FIELD } from 'validation';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import './Currencies.styles.scss';

const validation = Yup.object().shape({
  defaultCurrencyRate: Yup.string()
    .test('is-not-zero', 'Default currency rate has to be greater than zero.', (value) => {
      const parsedValue = parseFloat(value || '');
      return parsedValue > 0;
    })
    .required(MSG_REQUIRED_FIELD),
  secondaryCurrency: Yup.string().required(MSG_REQUIRED_FIELD),
  secondaryCurrencyRate: Yup.string()
    .test('is-not-zero', 'Secondary currency rate has to be greater or equal to zero.', (value) => {
      const parsedValue = parseFloat(value || '');
      return parsedValue >= 0;
    })

    .required(MSG_REQUIRED_FIELD),
});

const Currencies = () => {
  const {
    isOpen: isExchangeModalOpen,
    closeModal: closeExchangeModal,
    openModal: openExchangeModal,
  } = useModalState();
  const {
    isOpen: isRemoveCurrencyModalOpen,
    closeModal: closeRemoveCurrencyModal,
    openModal: openRemoveCurrencyModal,
  } = useModalState();

  const {
    currencies,
    updateCurrencies,
    getCurrencies,
    isUpdateInProgress,
    isLoading,
    removeSecondaryCurrency,
    isRemovingSecondaryCurrencyInProgress,
  } = useCurrenciesStore();
  const { defaultCurrency, secondaryCurrency, rate } = currencies || {};

  const methods = useForm<ExchangeFormValue>({
    mode: 'onTouched',
    resolver: yupResolver(validation),
    defaultValues: {
      defaultCurrencyRate: '1',
      secondaryCurrency: secondaryCurrency || '',
      secondaryCurrencyRate: rate?.toString(),
    },
  });

  const secondaryCurrencyOptions = useMemo(() => {
    return SUPPORTED_CURRENCIES.filter((currency) => currency.value !== defaultCurrency);
  }, [defaultCurrency]);

  const defaultCurrencyLabel = useMemo(
    () =>
      SUPPORTED_CURRENCIES.find((currency) => currency.value === defaultCurrency)?.label ||
      'Currency not recognized',
    [defaultCurrency],
  );

  const secondaryCurrencyLabel = useMemo(
    () =>
      SUPPORTED_CURRENCIES.find((currency) => currency.value === secondaryCurrency)?.label ||
      'Currency not recognized',
    [secondaryCurrency],
  );

  const handleSecondaryCurrencySelected = useCallback(
    (secondaryCurrency: Currency) => {
      updateCurrencies(
        { secondaryCurrency, defaultCurrency, rate: 0 },
        () => {
          snackbar.show({
            message: 'You have successfully set secondary currency.',
            type: 'success',
          });
        },
        (error: string) => {
          snackbar.show({ message: error, type: 'error' });
        },
      );
    },
    [defaultCurrency, updateCurrencies],
  );

  const handleExchangeFormSubmitted = useCallback(
    (values: ExchangeFormValue) => {
      const { secondaryCurrency, secondaryCurrencyRate, defaultCurrencyRate } = values;

      updateCurrencies(
        {
          secondaryCurrency: secondaryCurrency as Currency,
          defaultCurrency,
          rate: parseFloat(secondaryCurrencyRate) / parseFloat(defaultCurrencyRate),
        },
        () => {
          snackbar.show({
            message: 'You have successfully changed currency settings.',
            type: 'success',
          });
          closeExchangeModal();
        },
        (error: string) => {
          snackbar.show({ message: error, type: 'error' });
        },
      );
    },
    [defaultCurrency, updateCurrencies],
  );

  const handleRemoveCurrencyModalCofirmed = useCallback(() => {
    removeSecondaryCurrency(
      () => {
        snackbar.show({
          message: 'You have successfully removed secondary currency.',
          type: 'success',
        });
        closeExchangeModal();
        closeRemoveCurrencyModal();
      },
      (error: string) => {
        snackbar.show({ message: error, type: 'error' });
      },
    );
  }, [removeSecondaryCurrency, closeExchangeModal, closeRemoveCurrencyModal]);

  const openExchangeModalAndResetForm = useCallback(() => {
    methods.reset({
      defaultCurrencyRate: '1',
      secondaryCurrency: secondaryCurrency || '',
      secondaryCurrencyRate: rate?.toString(),
    });
    openExchangeModal();
  }, [secondaryCurrency, rate, methods, openExchangeModal]);

  useEffect(() => {
    if (!currencies) getCurrencies();
  }, []);

  if (isLoading) return <Loader />;

  return (
    <div className='currencies'>
      <div className='currencies__overview'>
        <div className='currencies__default-currency'>
          <label>Default currency</label>
          <div>{defaultCurrencyLabel}</div>
        </div>
        <div
          className={
            !secondaryCurrency ? 'currencies__secondary-currency' : 'currencies__default-currency'
          }
        >
          <label>Secondary currency</label>
          {!secondaryCurrency ? (
            isUpdateInProgress ? (
              <div className='currencies__secondary-loading-wrapper'>
                <Loader className='currencies__secondary-loading' />{' '}
              </div>
            ) : (
              <SelectV2
                value={secondaryCurrency || ''}
                onChange={(value) => handleSecondaryCurrencySelected(value.toString() as Currency)}
                options={secondaryCurrencyOptions}
                placeholder='Choose currency'
                className='currencies__secondary-select'
              />
            )
          ) : (
            <div>{secondaryCurrencyLabel}</div>
          )}
        </div>
      </div>
      {secondaryCurrency && (
        <div className='currencies__exchange-rate'>
          <div className='currencies__exchange-rate-title'>
            <label>Currency/Exchange (middle) rate</label>
            <Button
              type='button'
              variant='icon'
              onClick={openExchangeModalAndResetForm}
              aria-label='edit'
            >
              <PenIcon />
            </Button>
          </div>
          <table className='currencies__exchange-rate-content'>
            <thead>
              <tr>
                <th>{defaultCurrency}</th>
                <th>{secondaryCurrency}</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>1</td>
                <td>{rate || '/'}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}

      <ExchangeRateModal
        defaultCurrency={defaultCurrencyLabel}
        secondaryCurrencyOptions={secondaryCurrencyOptions}
        isSubmitInProgress={isUpdateInProgress}
        onSubmit={handleExchangeFormSubmitted}
        onRequestClose={closeExchangeModal}
        isOpen={isExchangeModalOpen}
        onRemoveBtnClicked={openRemoveCurrencyModal}
        methods={methods}
      />

      <RemoveSecondaryCurrencyModal
        isRemoveSecondaryCurrencyLoading={isRemovingSecondaryCurrencyInProgress}
        onRemove={handleRemoveCurrencyModalCofirmed}
        isOpen={isRemoveCurrencyModalOpen}
        onRequestClose={closeRemoveCurrencyModal}
      />
    </div>
  );
};

export default Currencies;
